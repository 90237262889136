<template>
  <v-card class="ml-0 pt-0" elevation="0">
    <v-card-title class="py-2">{{ noteTitle }}</v-card-title>
    <v-card-text v-if="showSection">
      <v-card
        v-for="(section, key) in sections"
        :key="key"
        elevation="2"
        class="my-0"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                v-model="section.header"
                placeholder="Name this section"
                :rules="[(v) => !!v || 'Section Name is required']"
                outlined
                dense
                required
                class="mb-2"
                append-outer-icon="mdi-delete-outline"
                @click:append-outer="removeSection(key)"
              />
              <tiptap-vuetify
                v-model="section.desc"
                :extensions="extensions"
                placeholder="Add details..."
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>

    <v-card-actions class="mb-3 pl-0">
      <v-btn outlined rounded text center @click="addSection">
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
} from 'tiptap-vuetify';

export default {
  name: 'PlayNotes',
  components: { TiptapVuetify },
  props: {
    noteTitle: {
      type: String,
      default: 'Notes',
    },
    buttonText: {
      type: String,
      default: 'Add Section',
    },
    showNotes: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Number,
      default: 0,
    },
    sections: {
      type: Array,
      required: true,
      default: function () {
        return [{ header: null, desc: null }];
      },
    },
  },
  data: () => ({
    showSection: false,
    count: 0,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      Paragraph,
      HardBreak,
    ],
  }),
  watch: {
    item: function (val) {
      this.count = val;
    },
    showNotes: function (val) {
      this.showSection = this.showNotes;
    },
  },
  methods: {
    addSection() {
      if (!this.showSection) {
        if (this.sections && this.sections.length === 0)
          this.sections.push({ header: null, desc: null });
        this.showSection = true;
      } else {
        this.sections.push({
          header: null,
          desc: null,
        });
      }
      this.count += 1;
    },
    removeSection(index) {
      if (this.count > 0) {
        this.count -= 1;
        this.sections.splice(index, 1);
      }

      if (this.count === 0) {
        this.showSection = false;
        this.sections.push({
          header: null,
          desc: null,
        });
      }
    },
  },
};
</script>
